import { Component, OnInit, HostListener } from '@angular/core';
import { DataService } from './members.data.service';
import { User, Member, MemberCourse, Course } from '../../models';
import { SelectItem } from 'primeng/api';
import { GlobalData } from 'src/app/global.data';
import { PrintService } from 'src/app/print/print.service';
import { ConnectionService } from '../settings.connection.service';

@Component({
  selector: 'members',
  templateUrl: './members.component.html',
  providers: [DataService],
  styles: [`.red{color:red;}`]
})
export class MembersComponent implements OnInit {

  user: User = new User();
  userId: number;
  users: User[] = [];
  member: Member = new Member();
  members: Member[];

  courses: Course[] = [];
  selectedCourse: Course = new Course();


  //selectEmployeePositions: SelectItem[] = [{ label: 'Выберите должность', value: null }];

  coursesSidebar: boolean = false;
  confirmDialog: boolean = false;
  confirmDeleteDialog: boolean = false;
  @HostListener('keydown.arrowright', ['$event'])
  onArrowRight(event: KeyboardEvent) {
  }
  @HostListener('keydown.arrowleft', ['$event'])
  onArrowLeft(event: KeyboardEvent) {
  }

  constructor(private dataService: DataService, private global: GlobalData, private printService: PrintService, private connectionService: ConnectionService) { }

  ngOnInit() {
    //this.loadMembers();
    this.loadUsers();
    this.loadCourses();
  }

  /*loadMembers() {
    this.dataService.getMembers()
      .subscribe((data: Member[]) => { this.members = data; });
  }*/
  loadUsers() {
    this.dataService.getUsers()
      .subscribe((data: User[]) => {
        this.users = data;
        if (this.userId) this.user = this.users.find(q => q.id == this.userId);
      });
    this.connectionService.members.emit(true);
  }

  loadCourses() {
    this.dataService.getCourses()
      .subscribe((data: Course[]) => { this.courses = data; });
  }

  genPassword() {
    var password = "";
    var symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 8; i++) {
      password += symbols.charAt(Math.floor(Math.random() * symbols.length));
    }
    this.user.password = password;
    this.user.login = this.user?.member?.email;
}

  /*save() {
    if (this.member.id == null) {
      this.dataService.createMember(this.member)
        .subscribe((data: Member) => this.members.push(data));
    } else {
      this.dataService.updateMember(this.member)
        .subscribe(data => this.loadMembers());
    }
    this.cancel();
  }*/

  save() {
    this.user.member.memberCourses.forEach(q => delete q.course);
    if (this.user.id == null) {
      this.dataService.createUser(this.user)
        .subscribe((data: User) => {
          this.users.push(data);
          this.loadUsers();
        });
    } else {
      this.dataService.updateUser(this.user)
        .subscribe((data: User) => {
          this.loadUsers();
        });
    }
    this.cancel();
  }
  
  cancel() {
    this.member = new Member();
    this.user = new User();
  }

  deleteMemberCourse(mc: MemberCourse) {
    this.dataService.deleteMemberCourse(mc.id)
      .subscribe(data => {
        this.user = new User();
        this.loadUsers();
      });
  }

  add() {
    this.cancel();
  }

  delete() {
    this.dataService.deleteUser(this.user.id)
      .subscribe(data => {
        this.user = new User();
        this.userId = null;
        this.loadUsers();
      });
  }

  openCoursesSidebar() {

  }

  addMemberCourse() {
    if (this.selectedCourse) {
      var newMemberCourse = new MemberCourse();
      newMemberCourse.courseId = this.selectedCourse.id;
      newMemberCourse.course = this.selectedCourse;
      if (this.user.member.memberCourses.find(q => q.courseId == this.selectedCourse.id)) return false;
      this.user.member.memberCourses.push(newMemberCourse);
      this.save(); 
    }
  }

  onRowUnselect() {
    this.cancel();
  }

  onRowSelect() {
    this.userId = this.user?.id;
  }

  confirm() {
    this.user.admin = !this.user.admin;
  }
  confirmDelete() {
    this.delete();
  }

}
