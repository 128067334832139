import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TabViewModule } from 'primeng/tabview';
import { ListboxModule } from 'primeng/listbox';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { SidebarModule } from 'primeng/sidebar';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';


import { TableModule } from 'primeng/table';
import { SettingsComponent } from './settings.component';
import { AuthInterceptor } from '../auth.interceptor';
import { MembersComponent } from './members/members.component';
import { CoursesComponent } from './courses/courses.component';
import { MemberCoursesComponent } from './memberCourses/memberCourses.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  imports: [CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forChild([
      { path: '', component: SettingsComponent }
    ]),
    TableModule,
    SidebarModule,
    TabViewModule,
    InputTextModule,
    InputMaskModule,
    ButtonModule,
    CheckboxModule,
    ListboxModule,
    DividerModule,
    FileUploadModule,
    ConfirmDialogModule,
    DialogModule
  ],
  declarations: [SettingsComponent,
    MembersComponent,
    MemberCoursesComponent,
    CoursesComponent],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }]
})
export class SettingsModule { }
