import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Member, User } from '../../models';

@Injectable()
export class DataService {

  private url = "/api/settings/member";
  private urlUser = "/api/settings/user";

  constructor(private http: HttpClient) {
  }

  getUsers() {
    return this.http.get("/api/settings/users");
  }
  createUser(user: User) {
    return this.http.post(this.urlUser, user);
  }
  updateUser(user: User) {
    return this.http.post(this.urlUser + "/update", user);
  }
  deleteUser(userId: number) {
    return this.http.get(this.urlUser + "/delete/" + userId);
  }

  getMembers() {
    return this.http.get("/api/settings/members");
  }
  createMember(member: Member) {
    return this.http.post(this.url, member);
  }
  updateMember(member: Member) {
    return this.http.post(this.url + "/update", member);
  }
  deleteMember(memberId: number) {
    return this.http.get(this.url + "/delete/" + memberId);
  }

  deleteMemberCourse(memberCourseId: number) {
    return this.http.get("/api/settings/memberCourse/delete/" + memberCourseId);
  }

  getCourses() {
    return this.http.get("/api/model/courses");
  }
}
