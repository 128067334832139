import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class ConnectionService {

  members = new EventEmitter<boolean>();
  courses = new EventEmitter<boolean>();

  constructor() {
  }
}
