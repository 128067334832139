import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { CertificateComponent } from './certificate/certificate.component';
import { TableModule } from 'primeng/table';

const routes: Routes = [
  {
    path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'certificate/:certificateId', component: CertificateComponent }
    ]
  },
  {
    path: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'certificate/:certificateId', component: CertificateComponent }
    ]
  }
];

@NgModule({
  imports: [CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    HttpClientModule,
    TableModule
  ],
  //exports: [RouterModule], 
  declarations: [PrintLayoutComponent, CertificateComponent],
  providers: []
})
export class PrintModule { }
