import { Component } from '@angular/core';
import { ConnectionService } from './settings.connection.service';

@Component({
  selector: 'app-home',
  templateUrl: './settings.component.html',
  providers: [ConnectionService]
})
export class SettingsComponent {

  constructor(private connectionService: ConnectionService) { }

}
