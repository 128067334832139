import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../auth.interceptor';

import { RouterModule } from '@angular/router';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';

import { CourseComponent } from './course.component';


@NgModule({
  imports: [CommonModule,
    FormsModule,
    RouterModule.forChild([{ path: '', component: CourseComponent }]),
    HttpClientModule,
    CardModule,
    ButtonModule
  ],
  exports: [CourseComponent, RouterModule],
  declarations: [CourseComponent],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }]
})
export class CourseModule { }
