import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from './login.data.service';
import { AuthGuard } from '../auth.guard';
import { User, Member } from '../models';
import { GlobalData } from '../global.data';

class AuthData {
    access_token: string;
    user: User;
    username: string;
}

@Component({
  selector: 'loginPage',
  templateUrl: 'login.component.html',
  providers: [DataService]
})
export class LoginComponent implements OnInit {

  login: string;
  password: string;
  save: boolean = false;
  denied: boolean = false;
  sidebar: any;


  constructor(private dataService: DataService, private global: GlobalData, private authGuard: AuthGuard, private router: Router) {
  }

  ngOnInit() {
    
    this.login = localStorage.getItem("login");
    this.password = localStorage.getItem("password");
    if (this.login) this.save = true;
  }

  auth() {
    this.dataService.login(this.login, this.password)
      .subscribe((data: AuthData) => {
        this.authGuard.setLoggedIn(true);

        this.global.setMember(data.user.member as Member);
        this.global.member.admin = data.user.admin;

        localStorage.setItem('id_token', data.access_token);
        localStorage.setItem('member', JSON.stringify(data.user.member));
        if (this.save) {
          localStorage.setItem('login', this.login);
          localStorage.setItem('password', this.password);
        }

        this.router.navigate(['/home']);
      }, (error: any) => {
        this.denied = true;
      });
  }

  keyDownFunction(event: any) {
    if (event.keyCode == 13) {
        this.auth();
    }
  }
}
