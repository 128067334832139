import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DataService {

  private url = "/api/model/course/";

  constructor(private http: HttpClient) {
  }

  getCourse(id: number) {
    return this.http.get(this.url + id);
  }

  downloadFile(link: string) {
    let headers = new HttpHeaders();
    return this.http.get("/files/" + link);
  }
}
