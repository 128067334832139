import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../auth.interceptor';

import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
/*import { TableWidgetModule } from '../widgets/table-widget/tablewidget.module';
import { TableSettingsModule } from '../widgets/table-settings/tablesettings.module';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';

import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { SidebarModule } from 'primeng/sidebar';
import { CalendarModule } from 'primeng/calendar';
import { ListboxModule } from 'primeng/listbox';
import { FieldsetModule } from 'primeng/fieldset';
import { ToolbarModule } from 'primeng/toolbar';
import { DialogModule } from 'primeng/dialog';*/

import { TestComponent } from './test.component';
import { RouterModule } from '@angular/router';


@NgModule({
  imports: [CommonModule,
    FormsModule,
    RouterModule.forChild([{ path: '', component: TestComponent }]),
    HttpClientModule,
    ButtonModule,
    CheckboxModule,
    RadioButtonModule],
  exports: [TestComponent, RouterModule],
  declarations: [TestComponent],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }]
})
export class TestModule { }
