import { Time } from "@angular/common";
import { SelectItem } from "primeng/api";
import { Data } from "@angular/router";

export class Member {
  public id: number;
  public surame: string;
  public name: string;
  public middlename: string;
  public birth: string;
  public email: string;
  public phone: string;
  public notice: string;
  public photoLink: string;

  public memberCourses: MemberCourse[];
  public archive: boolean;
  public admin: boolean;
  constructor() {
    this.memberCourses = [];
  }
}
export class User {
  public id: number;
  public memberId: number;
  public member: Member;
  public login: string;
  public password: string;
  public admin: boolean;
  constructor() {
    this.member = new Member();
  }
}
export class MemberCourse {
  public id: number;
  public memberId: number;
  public member: Member;
  public courseId: number;
  public course: Course;
  public questions: number;
  public right: number;
  public date: Date;
}
export class Course {
  public id: number;
  public name: string;
  public archive: boolean;
  public lectures: Lecture[];
  constructor() {
    this.lectures = [];
  }
}
export class MemberMark {
  public id: number;
  public memberId: number;
  public member: Member;
  public lectureId: number;
  public lecture: Lecture;
  public statusId: number;
  public status: Status;
  public questions: number;
  public right: number;
  public date: Date;
}
export class MemberAnswer {
  public id: number;
  public memberId: number;
  public member: Member;
  public answerId: number;
  public answer: Answer;
  public lectureId: number;
  public lecture: Lecture;
}
export class QuestionAnswerView {
  public id: number;
  public questionId: number;
  public question: Question;
  public answerId: number;
  public answers: Answer[];
  constructor() {
    this.answers = [];
  }
}

export class Lecture {
  public id: number;
  public number: number;
  public name: string;
  public fileLink: string;
  public mark: string;
  public archive: boolean;
  public questions: Question[];
  constructor() {
    this.questions = [];
  }
}
export class Question {
  public id: number;
  public number: number;
  public name: string;
  public archive: boolean;
  public answers: Answer[];
  constructor() {
    this.answers = [];
  }
}
export class Answer {
  public id: number;
  public number: number;
  public name: string;
  public right: boolean;
}
export class Status {
  public id: number;
  public name: string;
}

export class DataFile {
  public id: number;
  public name: string;
  public weight: string;
}
