import { Component, OnInit } from '@angular/core';
import { DataService } from './course.data.service';
import { Course, MemberMark } from '../models';
import { Router, ActivatedRoute } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { GlobalData } from '../global.data';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'course',
  templateUrl: './course.component.html',
  providers: [DataService]
})
export class CourseComponent implements OnInit {


  course: Course = new Course();
  memberMarks: MemberMark[] = []; 
  courseId: number;


  constructor(private dataService: DataService, private router: Router, public global: GlobalData, activeRoute: ActivatedRoute) {
    this.courseId = Number.parseInt(activeRoute.snapshot.params["id"]);
  }

  ngOnInit() {
    this.loadCourse();
  }

  loadCourse() {
    this.dataService.getCourse(this.courseId)
      .subscribe((data: any) => {
          data.course.lectures.sort((a, b) => {
            if (a.number > b.number) {
              return 1;
            } else {
              return -1;
            }
          });

        this.course = data.course;
        this.memberMarks = data.memberMarks;
      });
  }

  openTest(lectureId: number) {
    this.router.navigate(['/test', lectureId]);
  }

  openLink(link: string) {
  
    /*let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get("/files/" + link);
    this.dataService.downloadFile(link).subscribe((data: any) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
    });*/
    window.open("/files/" + link,"_blank")
  }
}

