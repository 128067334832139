import { Component, OnInit } from '@angular/core';
import { Member } from './models';
import { GlobalData } from './global.data';
import { Router } from '@angular/router';
import { PrintService } from './print/print.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  public loggedIn: boolean = false;
  public member: Member = new Member;

  constructor(public global: GlobalData, private router: Router, private printService: PrintService) {
  }

  ngOnInit() {
    this.loggedIn = false;
  }

  openCourse(id) {
    this.router.navigate(['/course', id]);
    console.log(id)
  }

  logout() {
    localStorage.setItem("id_token", "");
    this.global.setMember(new Member);
    this.router.navigate(['/login']);
  }

}
