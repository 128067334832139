import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';

import { HomeComponent } from './home.component';
import { AuthInterceptor } from '../auth.interceptor';

@NgModule({
    imports: [CommonModule,
        FormsModule,
        HttpClientModule,
    RouterModule.forChild([
      { path: '', component: HomeComponent }
    ]),
    CardModule,
    ButtonModule
    ],
  declarations: [HomeComponent],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    }]
})
export class HomeModule { }
