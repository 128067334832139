import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Member, User } from './models';
import { GlobalData } from './global.data';


@Injectable()
export class AuthGuard implements CanActivate {



  constructor(private global: GlobalData, private router: Router, private http: HttpClient) {
  }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    var path = state.url;

    if (!this.global.loggedIn) {
      this.http.get("/checktoken").subscribe((data: boolean) => {
        this.global.loggedIn = data;
        if (!this.global.loggedIn) {
          localStorage.setItem('member', '');
          this.router.navigate(['/login']);
          return false;
        } else {
          this.http.get("/getMember").subscribe((data: Member) => {
            localStorage.setItem('member', JSON.stringify(data));
            this.global.loggedIn = true;
            this.global.member = data;
          });

          this.router.navigate([path]);
        }
      }, () => {
          if (!this.global.loggedIn) {
          this.router.navigate(['/login']);
          return false;
        }
      });
    }
    // Доступ для сотрудников
    if (!this.global.member.admin &&
      (path == '/settings')) {
      console.log("access denied", this.global.member)
      this.router.navigate(['/home']);
      return false;
    }


    if (this.global.loggedIn) {
      return true;
    }
  }
  setLoggedIn(loggedIn: boolean) {
    console.log('login ' + loggedIn)
    this.global.setLoggedIn(loggedIn);
  }
}
