import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DataService {

  private url = "api/model/getCertificate";

  constructor(private http: HttpClient) {
  }

  getCertificate(id: number) {
    return this.http.get(this.url + "/" + id);
  }
}
