import { Component, OnInit } from '@angular/core';
import { MemberCourse } from '../models';
import { DataService } from './home.data.service';
import { GlobalData } from '../global.data';
import { Router } from '@angular/router';
import { PrintService } from '../print/print.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  providers: [DataService]
})
export class HomeComponent implements OnInit{
  memberCourses: MemberCourse[] = [];
  memberCourse: MemberCourse = new MemberCourse();


  constructor(private dataService: DataService, private router: Router, private global: GlobalData, private printService: PrintService) {
  }

  ngOnInit() {
    this.loadCourses();
  }
   
  loadCourses() {
    this.dataService.getMemberCourses().subscribe((data: MemberCourse[]) => this.memberCourses = data);
  }

  openCourse(id) {
    console.log(id)
    this.router.navigate(
      ['/course', id]);
  }

  printReport() {
    if (this.global.getMember()?.id) {
      this.printService.printDocument('certificate', this.global.getMember()?.id.toString());
    }
  }
}
