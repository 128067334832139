import { Component, OnInit, HostListener } from '@angular/core';
import { DataService } from './courses.data.service';
import { Course, Lecture, Question, Answer } from '../../models';
import { ConnectionService } from '../settings.connection.service';

@Component({
  selector: 'courses',
  templateUrl: './courses.component.html',
  providers: [DataService],
  styles: [`.grey{color:lightgrey;}
  .grey_bg{background:lightgrey !important;}
`]
})
export class CoursesComponent implements OnInit {

  courses: Course[] = [];
  course: Course = new Course();
  courseId: number;
  lecture: Lecture = new Lecture();

  questionDialog: boolean = false;
  confirmDialog: boolean = false;

  @HostListener('keydown.arrowright', ['$event'])
  onArrowRight(event: KeyboardEvent) {
  }
  @HostListener('keydown.arrowleft', ['$event'])
  onArrowLeft(event: KeyboardEvent) {
  }

  constructor(private dataService: DataService, private connectionService: ConnectionService) { }

  ngOnInit() {
    this.loadCourses();
  }


  // получаем данные через сервис
  loadCourses() {
    this.dataService.getCourses()
      .subscribe((data: Course[]) => {
        data.forEach(course => {
          course.lectures.sort((a, b) => {
            if (a.number > b.number) {
              return 1;
            } else {
              return -1;
            }
          });
        });

        this.courses = data;
        if (this.courseId) this.course = this.courses.find(q => q.id == this.courseId);
      });
      this.connectionService.courses.emit(true);
  }

  // сохранение данных
  save() {
    var i = 1;
    this.course.lectures.forEach(lecture => { lecture.number = i++; });
    if (this.course.id == null) {

      this.dataService.createCourse(this.course)
        .subscribe((data: Course) => {
          //this.courses.push(data)
          this.loadCourses();
        });
    } else {
      this.dataService.updateCourse(this.course)
        .subscribe(data => this.loadCourses());
    }
    this.cancel();
  }
  
  cancel() {
    this.lecture = new Lecture();
    this.course = new Course();
  }
  delete() {
    this.dataService.deleteCourse(this.course.id)
      .subscribe(data => {
        this.course = new Course();
        this.loadCourses();
      });
  }
  deleteLecture(l: Lecture) {
    this.dataService.deleteLecture(l.id)
      .subscribe(data => {
        this.loadCourses();
      });
  }
  deleteQuestion(q: Question) {
    this.dataService.deleteQuestion(q.id)
      .subscribe((data: Course) => {
      });

    const index = this.lecture.questions.indexOf(q, 0);
    if (index > -1) {
      this.lecture.questions.splice(index, 1);
    }
  }
  /*deleteAnswer(a: Answer) {
    const index = this.lecture.questions.indexOf(q, 0);
    if (index > -1) {
      this.lecture.questions.splice(index, 1);
    }
  }*/
  add() {
    this.cancel();
  }
  onRowUnselect() {
    console.log("unselect")
    this.cancel();
  }
  onRowSelect() {
    this.courseId = this.course.id;
  }
  addLecture() {
    var lecture = new Lecture();
    this.course.lectures.push(lecture);
  }
  openQuestions(lecture: Lecture) {
    this.questionDialog = true;
    //this.dataService.getQuestions(lectureId).subscribe((data: Question[]) => this.questions = data)
    this.lecture = lecture;
  }
  addQuestion() {
    console.log(this.lecture)
    this.lecture.questions.push(new Question());
  }
  addAnswer(question: Question) {
    console.log(question)
    question.answers.push(new Answer);
  }
  upload(event, lecture: Lecture) {
    lecture.fileLink = event.originalEvent.body.link;
  }
  confirm() {
    this.delete();
  }
}
