import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Course } from '../../models';

@Injectable()
export class DataService {

  private url = "/api/settings/course";

  constructor(private http: HttpClient) {
  }
   
  getQuestions(lectureId: number) {
    return this.http.get("/api/settings/qustions/" + lectureId);
  }   
  getCourses() {
    return this.http.get("/api/settings/courses");
  }
  createCourse(course: Course) {
    return this.http.post(this.url, course);
  }
  updateCourse(course: Course) {
    return this.http.post(this.url + "/update", course);
  }
  deleteCourse(courseId: number) {
    return this.http.get(this.url + "/delete/" + courseId);
  }
  deleteLecture(lectureId: number) {
    return this.http.get("/api/settings/lecture/delete/" + lectureId);
  }
  deleteQuestion(questionId: number) {
    return this.http.get("/api/settings/question/delete/" + questionId);
  }
}
