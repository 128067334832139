import { Injectable } from '@angular/core';
import { Member, User } from './models';

@Injectable({ providedIn: 'root' })
export class GlobalData {

  public loggedIn: boolean = false;
  public member: Member = new Member();
  readonly locale: any;
   
  constructor() {
    this.locale = {
        dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
        dayNamesShort: ["Вск", "Пон", "Вто", "Сре", "Чет", "Пят", "Суб"],
        dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        monthNamesShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
        today: 'Сегодня',
        clear: 'Очистить',
        weekHeader: 'Wk'
      
    };
  }

  public setLoggedIn(isLogged: boolean) {
    this.loggedIn = isLogged;
  }

  public getMember() {
    return this.member;
  }

  public setMember(member: Member) {
    this.member = member;
  }


  public getLocale() {
    return this.locale;
  }

  public isLoggedIn() {
    return this.loggedIn;
  }
}
