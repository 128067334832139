import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { MemberComponent } from './member.component';
import { AuthInterceptor } from '../auth.interceptor';

@NgModule({
    imports: [CommonModule,
        FormsModule,
        HttpClientModule,
    RouterModule.forChild([
      { path: '', component: MemberComponent }
    ]),
    ],
  declarations: [MemberComponent],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    }]
})
export class MemberModule { }
