import { Component, OnInit } from '@angular/core';
import { Course } from '../models';
import { DataService } from './member.data.service';
import { GlobalData } from '../global.data';

@Component({
  selector: 'member',
  templateUrl: './member.component.html',
  providers: [DataService]
})
export class MemberComponent implements OnInit{
  courses: Course[] = [];
  course: Course = new Course();


  constructor(private dataService: DataService, private global: GlobalData) {
  }

  ngOnInit() {
    this.loadCourses();
  }
   
  loadCourses() {
    this.dataService.getMemberCourses().subscribe((data: Course[]) => this.courses = data);
  }

  openCourse() {
    if (this.course.id) {
      window.open('/course/' + this.course.id, "_blank");
    }
  }

}
