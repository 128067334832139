import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DataService {

    constructor(private http: HttpClient) {
    }

    login(login: string, password: string) {
        return this.http.get('/token/?login=' + login + '&password=' + password);
    }

    getId() {
        return this.http.get('/getId/');
    }

    getDepartments() {
        return this.http.get("/api/model/departments");
    }

    getEquipments(departmentId: number) {
        return this.http.get("/api/dispatcher/equipments/" + departmentId);
    }

    getOperations(departmentId: number) {
        return this.http.get("/api/dispatcher/operations/" + departmentId);
    }
}