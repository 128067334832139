import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '../print.service';
import { DataService } from './certificate.data.service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.html',
  providers: [DataService],
  styleUrls: ['./certificate.css']
})
export class CertificateComponent implements OnInit {

  memberId: string;
  member: any;

  constructor(route: ActivatedRoute, private printService: PrintService, private dataService: DataService) {
    this.memberId = route.snapshot.params['certificateId'];
  }

  ngOnInit() {
    this.loadCertificate();
  }

  /*getInvoiceDetails(invoiceId) {
    const amount = Math.floor((Math.random() * 100));
    return new Promise(resolve =>
      setTimeout(() => resolve({ amount }), 1000)
    );
  }*/

  loadCertificate(/*id: number*/) {
    this.dataService.getCertificate(Number(this.memberId)).subscribe((data: any) => {
      this.member = data;
      this.printService.onDataReady();
    });
  }

}
