import { Component, OnInit } from '@angular/core';
import { DataService } from './test.data.service';
import { Question, MemberAnswer, QuestionAnswerView } from '../models';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalData } from '../global.data';

@Component({
  selector: 'test',
  templateUrl: './test.component.html',
  providers: [DataService]
})
export class TestComponent implements OnInit {

 // questions: Question[] = [];
  //question: Question = new Question();
  questionAnswers: QuestionAnswerView[] = [];
  questionAnswer: QuestionAnswerView = new QuestionAnswerView(); 

  selectedAnswers: any[] = [];
  lectureId: number;
  questionNumber: number = 0;
  lastQuestion: boolean = false;
  testOver: boolean = false;
  loading: boolean = false;
  mark: number;

  constructor(private dataService: DataService, private router: Router, public global: GlobalData, activeRoute: ActivatedRoute) {
    this.lectureId = Number.parseInt(activeRoute.snapshot.params["id"]);
  }

  ngOnInit() {
    this.loadQuestions();
  }

  loadQuestions() {
    this.dataService.getMemberAnswers(this.lectureId)
    this.dataService.getQuestions(this.lectureId)
      .subscribe((data: Question[]) => {
        this.questionNumber = 0;
        this.questionAnswers = data.map(q => {
          var qa = new QuestionAnswerView();
          qa.answers = q.answers;
          qa.question = q;
          qa.questionId = q.id;
          return qa;
        });
        if (this.questionAnswers[0]) this.questionAnswer = this.questionAnswers[0];
      });
  }

  previousQuestion() {
    if (this.questionNumber != 0) {
      this.questionNumber--;
      this.questionAnswer = this.questionAnswers[this.questionNumber];
      this.lastQuestion = false;
    }
  }

  nextQuestion() {
    if (this.questionNumber + 1 < this.questionAnswers.length) {
      this.questionNumber++;
      this.questionAnswer = this.questionAnswers[this.questionNumber];
      if (this.questionNumber + 1 == this.questionAnswers.length) this.lastQuestion = true;
    }
  }

  openQuestion(n: number) {
    if (n == undefined) return this.nextQuestion();
    this.questionNumber = n;
    this.questionAnswer = this.questionAnswers[this.questionNumber];
    this.lastQuestion = n + 1 == this.questionAnswers.length;
  }

  changeAnswer() {

  }

  over() {
    /*this.questionAnswers.forEach(q => {
      this.dataService.setMemberAnswer(q.answerId)
        .subscribe((data: any) => {

        });
    });*/
    if (!this.loading) {
      this.loading = true;
      //console.log("@", this.questionAnswers, this.selectedAnswers);
      //var answers = this.questionAnswers.map(q => { return q.answerId });
      this.dataService.setMemberAnswers(this.lectureId, this.selectedAnswers)
        .subscribe((data: any) => {
          this.dataService.getMark(this.lectureId).subscribe((data: number) => {
            this.mark = data;
            this.testOver = true;
            this.loading = false;
          });
        });
    }
  }

  calculate() {

  }

  retry() {
    this.questionAnswers = [];
    this.questionAnswer = new QuestionAnswerView();

    this.questionNumber = 0;
    this.lastQuestion = false;
    this.testOver = false;
    this.mark = 0;
    this.loadQuestions();
  }

}

