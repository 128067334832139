import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MemberAnswer } from '../models';

@Injectable()
export class DataService {

  private url = "/api/model/questionsByLecture/";

  constructor(private http: HttpClient) {
  }

  getQuestions(id: number) {
    return this.http.get(this.url + id);
  }

  getMemberAnswers(id: number) {
    return this.http.get("/api/model/memberAnswersByLecture/" + id);
  }

  setMemberAnswer(answerId: number) {
    return this.http.get("/api/model/memberAnswers/" + answerId);
  }

  setMemberAnswers(lectureId: number, answers: number[]) {
    return this.http.post("/api/model/memberAnswers/" + lectureId, answers);
  }

  getMark(id: number) {
    return this.http.get("/api/model/getMark/" + id);
  }
}
