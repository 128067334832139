import { Component, OnInit, HostListener } from '@angular/core';
import { DataService } from './memberCourses.data.service';
import { User, Member, MemberCourse, Course } from '../../models';
import { SelectItem } from 'primeng/api';
import { PrintService } from 'src/app/print/print.service';
import { ConnectionService } from '../settings.connection.service';

@Component({
  selector: 'memberCourses',
  templateUrl: './memberCourses.component.html',
  providers: [DataService],
  styles: [`.red{color:red;}`]
})
export class MemberCoursesComponent implements OnInit {

  memeberId: number;
  member: Member = new Member();
  members: Member[];

  courses: Course[] = [];
  selectedCourse: Course = new Course();

  coursesSidebar: boolean = false;
  confirmDialog: boolean = false;

  @HostListener('keydown.arrowright', ['$event'])
  onArrowRight(event: KeyboardEvent) {
  }
  @HostListener('keydown.arrowleft', ['$event'])
  onArrowLeft(event: KeyboardEvent) {
  }

  constructor(private dataService: DataService, private printService: PrintService, private connectionService: ConnectionService) {
    this.connectionService.courses.subscribe((data: any) => { this.loadCourses(); });
    this.connectionService.members.subscribe((data: any) => { this.loadMembers(); });
  }

  ngOnInit() {
    this.loadMembers();
    this.loadCourses();
  }
  loadMembers() {
    this.dataService.getMembers()
      .subscribe((data: Member[]) => { this.members = data; });
  }
  /*loadUsers() {
    this.dataService.me()
      .subscribe((data: User[]) => {
        console.log(this.user.id, "111");
        this.users = data;
        console.log(this.user, "111");
        if (this.memeberId0) this.member = this.members.find(q => q.id == this.member);
      });
  }*/

  loadCourses() {
    this.dataService.getCourses()
      .subscribe((data: Course[]) => { this.courses = data; });
  }



  save() {
    this.member.memberCourses.forEach(q => delete q.course);
    if (this.member.id != null) {
      this.dataService.updateMember(this.member)
        .subscribe((data: User) => {
          this.loadMembers();
        });
    }
    this.cancel();
  }

  cancel() {
    this.member = new Member();
  }

  deleteMemberCourse(mc: MemberCourse) {
    this.dataService.deleteMemberCourse(mc.id)
      .subscribe(data => {
        this.member = new Member();
        this.loadMembers();
      });
  }

  openCoursesSidebar() {
  }

  addMemberCourse() {
    if (this.selectedCourse) {
      var newMemberCourse = new MemberCourse();
      newMemberCourse.courseId = this.selectedCourse.id;
      newMemberCourse.course = this.selectedCourse;
      if (this.member.memberCourses.find(q => q.courseId == this.selectedCourse.id)) return false;
      this.member.memberCourses.push(newMemberCourse);
      this.save(); 
    }
  }

  onRowUnselect() {
    this.cancel();
  }

  onRowSelect() {
    this.memeberId = this.member?.id;
  }


  printReport() {
    if (this.member?.id) {
      this.printService.printDocument('certificate', this.member?.id.toString());
    }
  }
}
