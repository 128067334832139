import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { PrintService } from './print/print.service';

import { AppComponent } from './app.component';
import { AuthGuard } from './auth.guard';
import { AuthInterceptor } from './auth.interceptor';
import { GlobalData } from './global.data';
import { LoginModule } from './login/login.module';
import { HomeModule } from './home/home.module';
import { SettingsModule } from './settings/settings.module';
import { CourseModule } from './course/course.module';
import { MemberModule } from './member/member.module';
import { TestModule } from './test/test.module';
import { PrintModule } from './print/print.module';

const appRoutes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => HomeModule, canActivate: [AuthGuard] },
  { path: 'login', loadChildren: () => LoginModule },
  { path: 'member', loadChildren: () => MemberModule, canActivate: [AuthGuard] },
  { path: 'course/:id', loadChildren: () => CourseModule, canActivate: [AuthGuard] },
  { path: 'test/:id', loadChildren: () => TestModule, canActivate: [AuthGuard] },
  { path: 'settings', loadChildren: () => SettingsModule, canActivate: [AuthGuard] },
  { path: 'print', loadChildren: () => PrintModule }
];

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    PrintModule,
    RouterModule.forRoot(appRoutes)
  ],
  declarations: [
    AppComponent
  ],
  providers: [GlobalData,
    AuthGuard, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
    }, PrintService],
  bootstrap: [AppComponent]
})
export class AppModule { }
